<template>
	<v-card max-width="500" class="mx-auto mt-5" elevation="0" :color="color" :dark="dark" rounded="xl">
		<div class="d-flex justify-center mb-2">
			<v-btn plain fab active-class="" :to="{ name: 'Landing' }">
				<v-avatar size="96">
					<v-img src="@/assets/images/icon/logo-oversos-second-2x.png" />
				</v-avatar>
			</v-btn>
		</div>
		<v-card-title>
			<h1 class="display-1 mx-auto">
				{{ $t('auth.register') }}
			</h1>
		</v-card-title>
		<v-card-text class="pb-0">
			<v-form ref="form" v-model="valid">
				<v-subheader>{{ $t('auth.identification') }}</v-subheader>
				<v-text-field
					v-model="email"
					type="email"
					:label="$t('auth.email')"
					:hint="$t('auth.emailHelp')"
					:rules="rules.email"
					required
					outlined
					rounded
					prepend-inner-icon="mdi-email"
					hide-details="auto"
					class="pb-3"
				/>
				<v-text-field
					v-model="name.first"
					:label="$t('auth.firstName')"
					:rules="rules.name.first"
					required
					outlined
					rounded
					prepend-inner-icon="mdi-account-circle"
					hide-details="auto"
					class="pb-3"
				/>
				<v-text-field
					v-model="name.last"
					:label="$t('auth.lastName')"
					:rules="rules.name.last"
					required
					outlined
					rounded
					prepend-inner-icon="mdi-account-circle"
					hide-details="auto"
					class="pb-3"
				/>
				<v-subheader>{{ $t('auth.security') }}</v-subheader>
				<v-text-field
					v-model="password"
					:type="showPassword ? 'text' : 'password'"
					:label="$t('auth.password')"
					:hint="$t('auth.passwordHelp')"
					:rules="rules.password"
					required
					outlined
					rounded
					prepend-inner-icon="mdi-lock"
					hide-details="auto"
					class="pb-3"
					:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
					@click:append="showPassword = !showPassword"
				/>
				<v-text-field
					v-model="passwordAgain"
					:type="showPassword ? 'text' : 'password'"
					:label="$t('auth.passwordAgain')"
					:rules="rules.passwordAgain"
					required
					outlined
					rounded
					prepend-inner-icon="mdi-lock"
					hide-details="auto"
					class="pb-3"
					:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
					@click:append="showPassword = !showPassword"
				/>
				<v-subheader>{{ $t('auth.others') }}</v-subheader>
				<v-select
					v-model="locale"
					:items="availableLanguages"
					:label="$t('auth.language')"
					:rules="rules.locale"
					required
					outlined
					rounded
					hide-details="auto"
					class="pb-3"
					item-text="name"
					item-value="key"
					return-object
					prepend-inner-icon="mdi-translate"
				/>
				<v-checkbox v-model="agreeToTerms" :rules="rules.agreeToTerms" required>
					<template #label>
						<i18n path="auth.acceptTerms" tag="div">
							<template #url>
								<v-btn color="primary" text small @click.stop :to="{ name: 'Policies' }" target="_blank" class="text-decoration-none my-0">
									{{ $t('policies.terms&conditions') }}
								</v-btn>
							</template>
						</i18n>
					</template>
				</v-checkbox>
				<v-checkbox v-model="talentPool" class="my-0" :label="$t('auth.agreeToTalentPool')" />
				<v-checkbox v-model="promotionalEmails" class="my-0" :label="$t('emails.consentReceivePromotionalEmails')" />
			</v-form>
		</v-card-text>

		<v-card-actions class="d-flex align-center justify-center flex-wrap pt-0">
			<v-btn class="px-4" color="primary" large rounded :loading="loading" @click="callRegister()">
				{{ $t('auth.register') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'RegisterForm',
	props: {
		color: {
			type: String,
			default: '',
		},
		dark: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			valid: false,
			email: '',
			name: {
				first: '',
				last: '',
			},
			locale: null,
			password: '',
			passwordAgain: '',
			showPassword: false,
			agreeToTerms: false,
			talentPool: true,
			promotionalEmails: true,
			rules: {
				email: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.indexOf('@') !== 0) || this.$t('rules.emailUsername'),
					(v) => (v && v.includes('@')) || this.$t('rules.emailAtSymbol'),
					(v) => (v && v.includes('.')) || this.$t('rules.emailPeriodSymbol'),
					(v) => (v && v.indexOf('.') <= v.length - 3) || this.$t('rules.emailDomain'),
					(v) => (v && /.+@.+\..+/.test(v)) || this.$t('rules.emailValid'),
					(v) => (v && v.length <= this.emailMaxLength) || this.$t('rules.length', { length: this.emailMaxLength }),
				],
				name: {
					first: [
						(v) => !!v || this.$t('rules.required'),
						(v) => (v && v.length <= this.firstNameMaxLength) || this.$t('rules.length', { length: this.firstNameMaxLength }),
					],
					last: [
						(v) => !!v || this.$t('rules.required'),
						(v) => (v && v.length <= this.lastNameMaxLength) || this.$t('rules.length', { length: this.lastNameMaxLength }),
					],
				},
				locale: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && this.availableLanguages.map(({ key }) => key).includes(v.key)) || this.$t('rules.notSupported'),
				],
				password: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.passwordMaxLength) || this.$t('rules.length', { length: this.passwordMaxLength }),
				],
				passwordAgain: [(v) => v === this.password || this.$t('rules.passwordAgainMatch')],
				agreeToTerms: [(v) => !!v || this.$t('rules.agreeToTerms')],
			},
		}
	},
	computed: {
		emailMaxLength() {
			return 255
		},
		firstNameMaxLength() {
			return 64
		},
		lastNameMaxLength() {
			return 128
		},
		passwordMaxLength() {
			return 128
		},
		...mapGetters({
			availableLanguages: 'data/availableLanguages',
		}),
	},
	created() {
		this.fetchAvailableLanguages()
	},
	methods: {
		callRegister() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.register({
				email: this.email,
				name: this.name,
				locale: this.locale,
				password: this.password,
				passwordAgain: this.passwordAgain,
				talentPool: this.talentPool,
				promotionalEmails: this.promotionalEmails,
			})
				.then(({ success }) => {
					if (success) {
						this.$router.push({ name: 'AuthVerify' })
					}
				})
				.then(() => {
					this.loading = false
				})
		},
		...mapActions('auth', ['register']),
		...mapActions('data', ['fetchAvailableLanguages']),
	},
}
</script>
